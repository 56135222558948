/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */
export function listNames(entityList) {
  if(!entityList) {
    return null;
  }
  let names = [];
  entityList.forEach(element => names.push(element.name));
  return names;
}